// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, serverTimestamp } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD4-v6SBgWUjnkauUOScZ2zs2AClYR7-II",
  authDomain: "referoes.com",
  projectId: "referoes-cd020",
  storageBucket: "referoes-cd020.appspot.com",
  messagingSenderId: "149056660475",
  appId: "1:149056660475:web:fc050b7fadbfce2efbe15e",
  measurementId: "G-N78Z7C58LG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const projectFirestore = getFirestore();
const storage = getStorage();
const analytics = getAnalytics(app);
const timestamp = serverTimestamp();

export { projectFirestore, auth, timestamp, storage };